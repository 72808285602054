/* Existing styles for "Buy Me a Coffee" button */
.bmc-button {
  min-width: 210px;
  height: 60px;
  padding: 0 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  background-color: #5F7FFF;
  border-radius: 9999px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: 'Cookie', cursive;
  font-size: 28px;
  text-decoration: none;
}

.bmc-button:hover {
  background-color: #4b69e6;
}

.bmc-button img {
  width: 35px;
  margin-right: 8px;
}

/* New styles for responsive header */
.MuiAppBar-root {
  transition: all 0.3s ease;
}

.MuiToolbar-root {
  flex-wrap: wrap;
  justify-content: space-between;
}

.header-logo {
  display: flex;
  align-items: center;
}

.header-logo img {
  transition: all 0.3s ease;
}

.header-buttons {
  display: flex;
  align-items: center;
}

.header-buttons .MuiButton-root {
  margin: 0 5px;
}

@media (max-width: 600px) {
  .MuiToolbar-root {
    flex-direction: column;
    padding: 10px 0;
  }

  .header-logo {
    margin-bottom: 10px;
  }

  .header-logo img {
    width: 60px;
    height: 60px;
  }

  .header-buttons {
    flex-wrap: wrap;
    justify-content: center;
  }

  .header-buttons .MuiButton-root {
    font-size: 0.8rem;
    padding: 6px 8px;
  }

  .MuiIconButton-root {
    width: 30px;
    height: 30px;
  }

  .MuiIconButton-root .MuiSvgIcon-root {
    font-size: 1rem;
  }
}

/* Specific adjustments for iPhone 11 Pro */
@media (max-width: 375px) {
  .header-logo img {
    width: 50px;
    height: 50px;
  }

  .MuiTypography-root {
    font-size: 1rem;
  }

  .header-buttons .MuiButton-root {
    font-size: 0.7rem;
    padding: 4px 6px;
  }
}