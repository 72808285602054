body {
  margin: 0;
  font-family: 'Roboto', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1B262C;
  color: #BBE1FA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Global styles */
:root {
  --color-primary: #3282B8;
  --color-secondary: #BBE1FA;
  --color-background: #1B262C;
  --color-paper: #0F4C75;
  --color-text-primary: #BBE1FA;
  --color-text-secondary: #BBE1FA;
}
